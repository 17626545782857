import React, { Component } from "react"
import { graphql, navigate, Link } from "gatsby"
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import { ContentSidebarContainerNoFeaturedImage, LineBottom, HeaderSection, UnderlineMobile, UnderlineDesktop, OverviewPageH4, ReadMore, StyledArticle, PrevNextButton } from '../components/PageElements'
import underline from '../images/underline.png'
import { InteriorContent } from '../components/InteriorContent'
import Sidebar from '../components/Sidebar'
import WhatOurClientsAreSaying from "../components/WhatOurClientsAreSaying";
import Maps from '../components/Maps'


class BlogPage extends Component {
  renderPreviousLink = () => {
    const {
      pageContext: { pageNumber },
    } = this.props

    let previousLink = null

    if (!pageNumber) {
      return null
    } else if (1 === pageNumber) {
      previousLink = `/blog/`
    } else if (1 < pageNumber) {
      previousLink = `/blog/${pageNumber - 1}/`
    }

    return (
      <PrevNextButton onClick={() => navigate(previousLink)}>
        Previous Posts
      </PrevNextButton>
    )
  }

  renderNextLink = () => {
    const {
      pageContext: { hasNextPage, pageNumber },
    } = this.props
    const slug = this.props.location.pathname
    if (hasNextPage) {
      return (
        <PrevNextButton
          onClick={() => navigate(`/blog/${pageNumber + 1}/`)}
        >
          Next Posts
        </PrevNextButton>
      )
    }else if(slug === "/blog/"){
      return (
          <PrevNextButton
            onClick={() => navigate(`/blog/1/`)}
          >
            Next Posts
          </PrevNextButton>
        )
  } else {
        return (
            null
          )
    }
  }

  render() {
    const {
      data,
    } = this.props

    
    
    return (
        <Layout>
          <ContentSidebarContainerNoFeaturedImage>
          <InteriorContent>
        <HeaderSection>
          <h3>MMK Latest Blog</h3>
          <h1>How can our Auto Accident lawyers help you?</h1>
        <LineBottom>
    <UnderlineMobile>
    <Img fluid={data.file.childImageSharp.fluid} />
    </UnderlineMobile>
    <UnderlineDesktop>
    <img className="underline" src={underline} />
    </UnderlineDesktop>
    </LineBottom>
          </HeaderSection>
            {data &&
              data.wpgraphql &&
              data.wpgraphql.posts.nodes.map(post => (
                <StyledArticle key={post.id}>
                  <OverviewPageH4>
                    <Link to={post.uri} dangerouslySetInnerHTML={{ __html:post.title}} />
                      </OverviewPageH4>
                      <Link to={post.uri}>
                      <ReadMore>Read More</ReadMore>
                      </Link>
                </StyledArticle>
              ))}

            
              {this.renderPreviousLink()}
            
              {this.renderNextLink()}
            
            </InteriorContent>
            <Sidebar />
            </ContentSidebarContainerNoFeaturedImage>
            <WhatOurClientsAreSaying />
<Maps />
      </Layout>
    )
  }
}

export default BlogPage

export const query = graphql`
  query GET_POSTS($ids: [ID]) {
    wpgraphql {
      posts(where: { in: $ids }) {
        nodes {
            id
            uri
          title
        }
      }
    }
    file(relativePath: {eq: "underline.png"}) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
        fixed(width: 660, height: 18, quality: 100){
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`